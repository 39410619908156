/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import NProgress from 'nprogress';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useRouter } from 'next/router';
import { useApollo } from '../lib/useApollo';
import ToastProvider from '../context/ToastContext';
import '../styles.css';

// @ts-ignore
export default function MyApp({ Component, pageProps, err }: AppProps<any>) {
  const [token, setToken] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setToken(
        typeof sessionStorage.getItem('token') === 'undefined' ||
          sessionStorage.getItem('token') === null ||
          sessionStorage.getItem('token') === 'undefined'
          ? '{}'
          : JSON.parse(sessionStorage.getItem('token'))
      );
    }
  }, []);
  const apolloClient = useApollo({ ...pageProps.initialApolloState, token });
  const router = useRouter();
  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, [router]);
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>Plataforma Unidos</title>
        </Head>
        <ToastProvider>
          <Component {...pageProps} err={err} />
        </ToastProvider>
      </>
    </ApolloProvider>
  );
}
